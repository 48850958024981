exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-code-js": () => import("./../../../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-archive-ibm-ibm-projects-js": () => import("./../../../src/pages/portfolio/archive_ibm/ibm-projects.js" /* webpackChunkName: "component---src-pages-portfolio-archive-ibm-ibm-projects-js" */),
  "component---src-pages-portfolio-charles-schwab-js": () => import("./../../../src/pages/portfolio/charles-schwab.js" /* webpackChunkName: "component---src-pages-portfolio-charles-schwab-js" */),
  "component---src-pages-portfolio-discover-js": () => import("./../../../src/pages/portfolio/discover.js" /* webpackChunkName: "component---src-pages-portfolio-discover-js" */),
  "component---src-pages-portfolio-exxon-mobil-js": () => import("./../../../src/pages/portfolio/exxon-mobil.js" /* webpackChunkName: "component---src-pages-portfolio-exxon-mobil-js" */),
  "component---src-pages-portfolio-ibm-js": () => import("./../../../src/pages/portfolio/ibm.js" /* webpackChunkName: "component---src-pages-portfolio-ibm-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-kraft-js": () => import("./../../../src/pages/portfolio/kraft.js" /* webpackChunkName: "component---src-pages-portfolio-kraft-js" */),
  "component---src-pages-portfolio-nectar-js": () => import("./../../../src/pages/portfolio/nectar.js" /* webpackChunkName: "component---src-pages-portfolio-nectar-js" */),
  "component---src-pages-portfolio-sharedstreams-js": () => import("./../../../src/pages/portfolio/sharedstreams.js" /* webpackChunkName: "component---src-pages-portfolio-sharedstreams-js" */),
  "component---src-pages-portfolio-volvo-js": () => import("./../../../src/pages/portfolio/volvo.js" /* webpackChunkName: "component---src-pages-portfolio-volvo-js" */),
  "component---src-pages-ux-js": () => import("./../../../src/pages/ux.js" /* webpackChunkName: "component---src-pages-ux-js" */)
}

